import React, { Component } from "react";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import * as moment from 'moment';
import './date-picker.scss';
class PWDTPicker extends Component {

    render() {
        const { value, inputProps, onChange } = this.props;
        // console.log('value', value)
        return <Datetime
            value={value}
            inputProps={inputProps}
            timeFormat={false}
            dateFormat="DD-MM-YYYY"
            onChange={onChange}
            renderInput={this.renderInput}
            renderDay={this.renderDay}
            isValidDate={this.valid}
            closeOnSelect={true} />;
    }

    renderInput(props, openCalendar, closeCalendar) {

        return (
            <div style={{ display: 'flex' }}>
                <input {...props} />
                <div className="w3-input pw-wtf-input pw-input-img-wrap"
                    onClick={openCalendar} >

                </div>
                {/* <button onClick={openCalendar}>open calendar</button>
                <button onClick={closeCalendar}>close calendar</button>
                <button onClick={clear}>clear</button> */}
            </div>
        );
    }

    renderDay(props, currentDate, selectedDate) {

        if (currentDate.day() == 0) {
            return <td style={{ color: '#fcc90f' }} {...props}>{currentDate.date()}</td>;
        }
        else {
            return <td {...props}>{currentDate.date()}</td>;
        }
    }
    valid(current) {
        var today = moment().add(0, 'day');
        return current.isBefore(today);
    }
}
export default PWDTPicker