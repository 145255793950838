import React, { useState } from 'react';
import Layout from '../../components/layout';
import BackArrow from "../../images/icons/arrow-left.png"
import { Link, navigate } from "gatsby"
import SEO from '../../components/seo';
import DropDown from '../../components/common/DropDown';
import * as moment from 'moment';
import PWDTPicker from '../../components/common/DatePicker';
import ThankYou from '../../components/ContactForm/ThankYou';

const SmQuotesGroupIcon = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/sm-quotes-group.png';
const QuotesGroupIcon = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png';

const WiseTipSurveyForm = ({ location, pageContext: { data } }) => {

    const initialData = {

        question1: null,
        question2: null,
        question3: null,
        question4: null,
        question5: null,
        question6: null,
        question7: [],
        question8: null,
        question9: null,
        question10: null,
        question11: null,
        question12: [],
        question13: null,
        question14: null,
        question15: null,
        question16: null,
        question17: null,
        question18: null,
        question19: null,
        question20: null,
        question21: null,
        luckyDrawOptOut: false,
        fName: '',
        phNumber: '',
        email: '',
        terms_agree: false,
        // question24: false,
        question18_5: '',
        question18_6: '',
        question19_6: ''

    }
    const dobInitialData = {
        date0: null,
        date1: null,
        date2: null,
        date3: null,
        date4: null,
        date5: null,
        date6: null,
        date7: null,
        date8: null,
        date9: null
    }

    const [formData, setFormData] = useState(initialData);
    const [errors, setErrors] = useState({});
    const [error12, setError12] = useState({});
    const [dobData, setDobData] = useState(dobInitialData);
    const [luckyDraw, setLuckyDraw] = useState(false);
    const [inFlight, setInFlight] = useState(false);
    const [showThankYou, setShowThankYou] = useState('w3-hide');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
        if (name == 'phNumber')
            errors.email = ''
        if (name == 'email')
            errors.phNumber = ''
        if (name == 'question18') {
            errors.question18_5 = ''
            errors.question18_6 = ''
            setFormData({
                ...formData,
                [name]: value,
                ['question18_5']: '',
                ['question18_6']: ''
            });
        }
        if (name == 'question19') {
            errors.question19_6 = ''
            setFormData({
                ...formData,
                [name]: value,
                ['question19_6']: ''
            });
        }

        handleErrors(name);
    }

    const handleCheckbox = (event) => {
        const { name, value } = event.target;
        if (event.target.checked) {
            formData.question12.push(value)

            if (formData.question12.length > 0) {
                error12.question12 = '';
                setError12({ ...error12 });
            }
        }
        else {
            var index = formData.question12.indexOf(value)
            formData.question12.splice(index, 1)
        }
    }

    const handleLuckyDraw = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: event.target.checked
        });
        if (event.target.checked) {
            errors.fName = '';
            errors.phNumber = '';
            errors.email = '';

            setLuckyDraw(true)
        }
        else {
            setLuckyDraw(false)
        }
        handleErrors(name);

    }


    const handleErrors = (name) => {
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
    }

    const handleSelect = (selected) => {
        handleErrors('question6');
        setFormData({
            ...formData,
            question6: selected
        });
    }

    let questions = {
        question1_5_14: [
            { value: 'Yes' },
            { value: 'No' }
        ],
        question3: [
            { value: 'Currently a parent' },
            { value: 'Soon to be a parent (within the next year)' },
            { value: 'Currently a grandparent' },
            { value: 'Soon to be a grandparent (within the next year)' },
            { value: 'None of the above' }
        ],
        question4: [
            { value: 'Male' },
            { value: 'Female' },
            { value: 'Prefer not to disclose' }
        ],
        question6: [
            { key: "1", value: '1' },
            { key: "2", value: '2' },
            { key: "3", value: '3' },
            { key: "4", value: '4' },
            { key: "5", value: '5' },
            { key: "6", value: '6' },
            { key: "7", value: '7' },
            { key: "8", value: '8' },
            { key: "9", value: '9' },
            { key: "10", value: '10' },
        ],
        question8: [
            { value: 'Below $3,500' },
            { value: 'Between $3,500 to $7,800' },
            { value: 'Between $7,800 to $14,000' },
            { value: 'Above $14,000' }
        ],
        question10: [
            { value: 'Chinese' },
            { value: 'Malay' },
            { value: 'Indian' },
            { value: 'Others' }
        ],
        question11_12: [
            { value: 'English' },
            { value: 'Malay' },
            { value: 'Chinese' },
            { value: 'Tamil' },
            { value: 'Others' }
        ],
        question13: [
            { value: 'Singaporean Citizen' },
            { value: 'Singapore Permanent Resident (PR)' },
            { value: 'Others' }
        ],
        question15_17: [
            { value: 'Not at all' },
            { value: 'A little' },
            { value: 'A moderate amount' },
            { value: 'A lot' },
            { value: 'A great deal' }
        ],
        question16_20_21: [
            { value: 'Extremely unlikely' },
            { value: 'Somewhat unlikely' },
            { value: 'Neither likely nor unlikely' },
            { value: 'Somewhat likely' },
            { value: 'Extremely likely' }
        ],
        question18: [
            { value: 'Instagram' },
            { value: 'Facebook' },
            { value: 'Word-of-mouth (Friend)' },
            { value: 'Word-of-mouth (Family member)' },
            { value: 'Word-of-mouth (Others)' },
            { value: 'Others' }
        ],
        question19: [
            { value: 'I saw a post on Instagram' },
            { value: 'I saw a post on Facebook' },
            { value: 'I saw a link on WhatsApp (Official ParentWise broadcast)' },
            { value: 'I was sent a link from a friend' },
            { value: 'I was browsing the website' },
            { value: 'Others' }
        ],
    }

    const getOptionValues = (no, item) => {
        let name = 'question' + no
        return (
            <div style={{ marginBottom: '8px' }}>
                <label className="pw-wtf-radio-input">
                    <span className="pw-wtf-radio-small">
                        <input type="radio" name={name} value={item.value}
                            onChange={(e) => handleChange(e)} />
                        <span className="pw-wtf-graphical-radio"></span>
                    </span>
                    {/* <span className="pw-wtf-graphical-radio"></span> */}
                    <div className="pw-wtf-radio-label">{item.value}</div>
                </label>
            </div>
        )
    }

    const getDatePicker = () => {
        if (formData.question6) {
            let arr = []
            arr = questions.question6.slice(0, formData.question6)
            return (
                arr.map((item, i) => (
                    <div className="w3-margin-top" key={i}>
                        <PWDTPicker
                            value={dobData['date' + i]}
                            inputProps={{
                                className: 'w3-input pw-wtf-input pw-date-input'
                                , placeholder: "Child " + (i + 1)
                                , disabled: true
                            }}
                            onChange={date => handleDobData(date, i)} />
                        <div className="pw-error-text">{errors['date' + i]}</div>
                    </div>
                ))
            )
        }
    }

    const validateData = () => {
        const emailPattern = /^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        let hasErrors = false;
        Object.keys(formData).forEach((key) => {

            if (key == 'question7') {
                formData.question7 = []

                for (let i = 0; i < formData.question6; i++) {
                    let name = 'date' + i
                    if (dobData[name]) {
                        formData.question7.push(moment(dobData[name]).toDate().getTime())
                    }
                    else {
                        hasErrors = true;
                        errors[name] = 'This field is mandatory';
                    }
                }

            }
            else if (key == 'question12') {
                if (formData.question12.length == 0) {
                    hasErrors = true;
                    error12[key] = 'This field is mandatory';
                }
            }
            else if (key != 'question18_5' && key != 'question18_6' && key != 'question19_6' && key != 'luckyDrawOptOut'
                && key != 'fName' && key != 'phNumber' && key != 'email') {

                if (!formData[key]) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
                if (formData['question2']) {
                    const pattern = /[0-9]{3}[A-Za-z]{1}/;
                    if (!pattern.test(formData.question2)) {
                        hasErrors = true;
                        errors.question2 = 'Please provide ONLY the last 3 digits and letter of your NRIC/FIN';
                    }
                }
                if (formData['question18']) {
                    if (formData.question18 == 'Word-of-mouth (Others)' && !formData['question18_5']) {
                        hasErrors = true;
                        errors.question18_5 = 'This field is mandatory';
                    }
                    if (formData.question18 == 'Others' && !formData['question18_6']) {
                        hasErrors = true;
                        errors.question18_6 = 'This field is mandatory';
                    }
                }
                if (formData['question19']) {
                    if (formData.question19 == 'Others' && !formData['question19_6']) {
                        hasErrors = true;
                        errors.question19_6 = 'This field is mandatory';
                    }
                }
            }

            else if (key == 'luckyDrawOptOut' && !formData[key]) {
                if (!formData['fName']) {
                    hasErrors = true;
                    errors.fName = 'This field is mandatory';
                }
                if (!formData['phNumber'] && !formData['email']) {
                    hasErrors = true;
                    // errors.phNumber = 'This field is mandatory';
                    // errors.email = 'This field is mandatory';
                    errors.email = 'Enter either your email or phone number';
                }
                else {
                    errors.phNumber = '';
                    errors.email = '';
                }
                // if (!formData['email']) {
                //     hasErrors = true;
                //     errors.email = 'This field is mandatory';
                // }
                if (formData['email']) {
                    if (!emailPattern.test(formData.email)) {
                        hasErrors = true;
                        errors.email = 'Enter a valid email';
                    }
                }
            }
        });
        // console.log('formData', formData)

        if (hasErrors) {
            setErrors({ ...errors });
            setError12({ ...error12 });
            return false;
        }

        return formData;
    }

    const handleSubmit = () => {
        const validData = validateData();
        if (!validData) {
            return;
        }

        validData['type'] = 'wiseTip';
        validData['slug'] = data.slug;

        setInFlight(true);

        fetch(`${process.env.GATSBY_API_BASE}/api/v1/survey-response/wise-tip/add`, {
            "headers": {
                'Content-Type': 'application/json'
            },
            "body": JSON.stringify(validData),
            "method": "POST",
        }).then(resp => {
            if (resp && resp.ok) {
                setShowThankYou('w3-show');
            } else {
                setInFlight(false);
                alert("Something went wrong. Please try again!");
            }
        }).catch(err => {
            setInFlight(false);
            console.log('error ', err);
        });
    }

    const handleDobData = (date, i) => {
        let name = 'date' + i
        setDobData({
            ...dobData,
            [name]: date
        });

        errors[name] = '';
        // errors.question7 = ''
    }

    const numberValidate = (event) => {
        if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    return (
        <div className="pw-wt-page pw-wtf-background-img">
            {/* pw-wtf-background */}
            <div className="w3-display-container">
                {/* ab-us-os-background */}
                <img loading="lazy" src={QuotesGroupIcon} className="pw-wt-top-right-texture w3-hide-small w3-hide-medium" />
                <img loading="lazy" src={SmQuotesGroupIcon} className="pw-wt-top-right-sm-texture w3-hide-medium w3-hide-large" style={{ top: '28px' }} />
                <Layout>
                    <SEO
                        title="WiseTip Survey"
                        path={location.pathname}
                    />
                    <div className="w3-row pw-wt-list-container">
                        <div className="w3-container pw-wise-tip-feedback">
                            <div className="w3-row">
                                <Link to="/wise-tips" className="pw-wt-detail-back-to pw-wtf-back-arrow">
                                    <img src={BackArrow} className="pw-wt-detail-back-icon" />
                                    <div>
                                        Back to WiseTips
                                    </div>
                                </Link>
                            </div>

                            <div className="pw-wtf-main-title w3-hide-small">WiseTip Feedback Survey</div>
                            <div className="pw-wtf-main-title w3-hide-large w3-hide-medium">WISETIP FEEDBACK SURVEY</div>
                        </div>

                        <div className="w3-row pw-wise-tip-feedback ">
                            {/* pw-wtf-background */}
                            <div className="pw-wtf-content">
                                <div>
                                    Thank you for taking this survey! It should take less than 5 minutes, and you’ll stand a chance to win $10 in our lucky draw upon submitting your response.
                                    The information you provide will help us improve the ParentWise initiative and better understand how WiseTips can meet parents’ needs.
                                    Your data will be anonymised so that your responses cannot be traced back to you.
                                    It will be handled with confidentiality and never be shared with any third parties without your consent.
                                    If you have any questions or concerns about this survey, please contact Dr Cheryl Seah at
                                    &nbsp; <a style={{ color: '#FD4E68' }} href="mailto:Cheryl.seah@ceiglobal.org" target="_blank">Cheryl.seah@ceiglobal.org.</a>
                                </div>

                                <ol className="pw-wtf-margin-left-20">
                                    <li className="pw-wtf-question">
                                        Have you answered a similar, short survey from ParentWise previously?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question1_5_14.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(1, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question1}</div>

                                    <li className="pw-wtf-question">
                                        Please provide the last 3 digits and letter of your NRIC/FIN.
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        <input className="w3-input w3-margin-top"
                                            style={{ background: 'transparent', outline: 'none' }}
                                            type="text"
                                            placeholder="Type your answer here…"
                                            name="question2"
                                            onChange={(e) => handleChange(e)}
                                            maxLength="4" />
                                    </div>
                                    <div className="pw-error-text">{errors.question2}</div>

                                    <li className="pw-wtf-question">
                                        Are you a parent or grandparent?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question3.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(3, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question3}</div>

                                    <li className="pw-wtf-question">
                                        What is your gender?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question4.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(4, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question4}</div>

                                    <li className="pw-wtf-question">
                                        Are you the main caregiver of your child(ren)/grandchild(ren)?
                                        <i style={{ fontWeight: '500' }}> The main caregiver is the person who spends the most time with the child(ren).</i>
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question1_5_14.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(5, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question5}</div>

                                    <li className="pw-wtf-question">
                                        How many children/grandchildren do you have under your care?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top pw-wtf-input-width">
                                        <DropDown
                                            placeholder="Select here"
                                            customCls="pw-wtf-select"
                                            options={questions.question6}
                                            selectedValue={formData.question6}
                                            onChange={handleSelect}
                                        />
                                    </div>
                                    <div className="pw-error-text">{errors.question6}</div>

                                    <li className="pw-wtf-question">
                                        What is the date of birth of each of the children/grandchildren under your care?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top pw-wtf-input-width pw-wtf-input-width">
                                        {getDatePicker()}
                                    </div>

                                    {/* <div className="pw-error-text">{errors.question7}</div> */}

                                    <li className="pw-wtf-question">
                                        What is your monthly household income range?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question8.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(8, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question8}</div>

                                    <li className="pw-wtf-question">
                                        How many people are living in your household?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        <input className="w3-input w3-margin-top"
                                            style={{ background: 'transparent', outline: 'none' }}
                                            type="text"
                                            placeholder="Type your answer here…"
                                            name="question9"
                                            onChange={(e) => handleChange(e)}
                                            onKeyPress={(e) => numberValidate(e)}
                                            maxLength="2"
                                        />
                                    </div>
                                    <div className="pw-error-text">{errors.question9}</div>

                                    <li className="pw-wtf-question">
                                        What is your ethnicity?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question10.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(10, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question10}</div>

                                    <li className="pw-wtf-question">
                                        What is your main language spoken at home?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question11_12.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(11, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question11}</div>

                                    <li className="pw-wtf-question">
                                        What other languages do you speak at home? (You may select more than one option.)
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question11_12.map((item, i) => (
                                            <div key={i} style={{ marginBottom: '8px' }}>
                                                <label className="pw-wtf-radio-input">
                                                    <span className="pw-wtf-radio-small">
                                                        <input type="checkbox" name="checkboxVal" value={item.value}
                                                            onChange={(e) => handleCheckbox(e)} />
                                                        <span className="pw-wtf-graphical-radio"></span>
                                                    </span>
                                                    <div className="pw-wtf-radio-label">{item.value}</div>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{error12.question12}</div>

                                    <li className="pw-wtf-question">
                                        What is your nationality?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question13.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(13, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question13}</div>

                                    <div className="pw-wtf-sub-head">About the WiseTip</div>
                                    <li className="pw-wtf-question" style={{ marginTop: '8px' }}>
                                        Was the WiseTip displayed new to you?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question1_5_14.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(14, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question14}</div>

                                    <li className="pw-wtf-question">
                                        How much did the WiseTip address a concern you had?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question15_17.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(15, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question15}</div>

                                    <li className="pw-wtf-question">
                                        How likely are you to use this WiseTip with your child/grandchild?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question16_20_21.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(16, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question16}</div>

                                    <li className="pw-wtf-question">
                                        To what extent do you trust the information in this WiseTip?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question15_17.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(17, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question17}</div>

                                    <div className="pw-wtf-sub-head">About ParentWise</div>
                                    <li className="pw-wtf-question" style={{ marginTop: '8px' }}>
                                        Where did you first hear about the ParentWise platform?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question18.map((item, i) => (
                                            <div key={i} style={{ marginBottom: '8px' }}>
                                                <label className="pw-wtf-radio-input">
                                                    <span className="pw-wtf-radio-small">
                                                        <input type="radio" name="question18" value={item.value}
                                                            onChange={(e) => handleChange(e)} />
                                                        <span className="pw-wtf-graphical-radio"></span>
                                                    </span>
                                                    <div className="pw-wtf-radio-label">{item.value}</div>
                                                    {i == 4 ?
                                                        (
                                                            <span>
                                                                <input className="w3-input pw-wtf-input-wrap"
                                                                    type="text"
                                                                    placeholder="Type your answer here…"
                                                                    name="question18_5"
                                                                    onChange={(e) => handleChange(e)}
                                                                    value={formData.question18_5}
                                                                />
                                                                <br />
                                                                {!errors.question18_5 ? null :
                                                                    <span>
                                                                        <span className="pw-error-text pw-wtf-input-wrap" style={{ top: '0px' }}>{errors.question18_5}</span>
                                                                    </span>
                                                                }
                                                            </span>
                                                        ) : null}
                                                    {i == 5 ?
                                                        (
                                                            <span>
                                                                <input className="w3-input pw-wtf-input-wrap"
                                                                    style={{ left: '90px' }}
                                                                    type="text"
                                                                    placeholder="Type your answer here…"
                                                                    name="question18_6"
                                                                    onChange={(e) => handleChange(e)}
                                                                    value={formData.question18_6}
                                                                />
                                                                <br />
                                                                {!errors.question18_6 ? null :
                                                                    <span>
                                                                        <span className="pw-error-text pw-wtf-input-wrap" style={{ top: '0px', left: '90px' }}>{errors.question18_6}</span>
                                                                    </span>
                                                                }
                                                            </span>
                                                        ) : null}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question18}</div>

                                    <li className="pw-wtf-question">
                                        How did you get to this WiseTip?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question19.map((item, i) => (
                                            <div key={i} style={{ marginBottom: '8px' }}>
                                                <label className="pw-wtf-radio-input">
                                                    <span className="pw-wtf-radio-small">
                                                        <input type="radio" name="question19" value={item.value}
                                                            onChange={(e) => handleChange(e)} />
                                                        <span className="pw-wtf-graphical-radio"></span>
                                                    </span>
                                                    <div className="pw-wtf-radio-label">{item.value}</div>
                                                </label>
                                                {i == 5 ?
                                                    (
                                                        <span>
                                                            <input className="w3-input pw-wtf-input-wrap"
                                                                style={{ left: '90px' }}
                                                                type="text"
                                                                placeholder="Type your answer here…"
                                                                name="question19_6"
                                                                onChange={(e) => handleChange(e)}
                                                                value={formData.question19_6}
                                                            />
                                                            <br />
                                                            {!errors.question19_6 ? null :
                                                                <span>
                                                                    <span className="pw-error-text pw-wtf-input-wrap" style={{ top: '0px', left: '90px' }}>{errors.question19_6}</span>
                                                                </span>
                                                            }
                                                        </span>
                                                    ) : null}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question19}</div>

                                    <li className="pw-wtf-question">
                                        How likely are you to further explore the ParentWise platform?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question16_20_21.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(20, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question20}</div>

                                    <li className="pw-wtf-question">
                                        How likely are you to recommend the ParentWise platform to others?
                                    </li>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top">
                                        {questions.question16_20_21.map((item, i) => (
                                            <div key={i}>
                                                {getOptionValues(21, item)}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="pw-error-text">{errors.question21}</div>

                                    <div className="pw-wtf-sub-head">Lucky Draw</div>
                                    <div className="pw-wtf-margin-left-20 w3-margin-top" >
                                        Thank you for completing the survey! If you would like to enter the lucky draw,
                                        please leave your name and either your email or phone number so that we can get in touch with you if you win.
                                        Your name and contact will be deleted once all winners of the draw have been notified.

                                        <div className='pw-lucky-draw' style={{ marginTop: '28px' }}>
                                            <input className="w3-input pw-wtf-input"
                                                placeholder="Full Name"
                                                type="text"
                                                name="fName"
                                                onChange={(e) => handleChange(e)}
                                                disabled={luckyDraw}
                                                value={formData.fName}
                                                style={{ background: '#FFF' }}
                                            />
                                            <div className="pw-error-text">{errors.fName}</div>

                                            <input className="w3-input pw-wtf-input"
                                                placeholder="Phone Number"
                                                type="text"
                                                name="phNumber"
                                                onChange={(e) => handleChange(e)}
                                                onKeyPress={(e) => numberValidate(e)}
                                                disabled={luckyDraw}
                                                value={formData.phNumber}
                                                style={{ background: '#FFF' }}
                                                maxLength="8"
                                            />
                                            <div className="pw-error-text">{errors.phNumber}</div>

                                            <input className="w3-input pw-wtf-input"
                                                placeholder="Email"
                                                type="text"
                                                name="email"
                                                onChange={(e) => handleChange(e)}
                                                disabled={luckyDraw}
                                                value={formData.email}
                                                style={{ background: '#FFF' }}
                                            />
                                            <div className="pw-error-text">{errors.email}</div>

                                            <div style={{ marginTop: '30px' }}>
                                                <label className="pw-wtf-radio-input">
                                                    <input type="checkbox" name="luckyDrawOptOut"
                                                        onChange={(e) => handleLuckyDraw(e)} />
                                                    <span className="pw-wtf-graphical-radio"></span>
                                                    <div className="pw-wtf-radio-label" >Not interested in lucky draw</div>
                                                </label>
                                            </div>
                                            <div className="pw-error-text">{errors.luckyDrawOptOut}</div>
                                        </div>

                                        <div style={{ marginTop: '50px' }}>
                                            <label className="pw-wtf-radio-input">
                                                <input type="radio" name="terms_agree" value={true}
                                                    onChange={(e) => handleChange(e)} />
                                                <span className="pw-wtf-graphical-radio"></span>
                                                <div className="pw-wtf-radio-label">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                                    sed do eiusmod tempor et dolore magna aliqua. Ut enim ad minim veniam,
                                                    quis nostrud exercitation laboris aliquip ex ea commodo consequat.
                                                    Duis aute irure dolor in reprehenderit.
                                                    </div>
                                            </label>
                                        </div>
                                        <div className="pw-error-text">{errors.terms_agree}</div>

                                        <div className="w3-col s12 m12 pw-wtf-submit-section" style={{ marginBottom: '40px' }}>
                                            <button className="w3-round-xxlarge w3-button pw-wtf-submit-btn" disabled={inFlight} onClick={handleSubmit} >
                                                <div className="pw-wtf-sub-btn-text">
                                                    Submit Response <span className="pw-wtf-sub-btn-arrow" />
                                                </div>
                                            </button>
                                        </div>

                                        {inFlight == true && showThankYou == "w3-hide" ?
                                            <div className="w3-modal pw-loader-main">
                                                <div className="pw-loader pw-size"></div>
                                            </div>
                                            : <ThankYou
                                                openCls={showThankYou}
                                                closeHandler={() => navigate(`/wise-tips`, { replace: true })}
                                                description={<div>Thank you for your valuable feedback.<br />Your response has been recorded.</div>}
                                                link="/wise-tips"
                                            />
                                        }
                                    </div>
                                </ol>
                            </div>
                        </div>
                    </div>
                </Layout>
            </div>
        </div>
    )
}

export default WiseTipSurveyForm

